import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataExchangeService {
  constructor() {}

  // optInStatus is a BehaviorSubject that will hold the current opt-in status
  optInStatus = new BehaviorSubject('');
  currentOptInStatus = this.optInStatus.asObservable();
  updateOptInStatus(newOptInStatus: string) {
    this.optInStatus.next(newOptInStatus);
  }
  
  // loadingSpinner is a BehaviorSubject that will hold the current loadingSpinner boolean
  loadingSpinnerStatus = new BehaviorSubject('');
  currentLoadingSpinnerStatus = this.loadingSpinnerStatus.asObservable();
  updateLoadingSpinnerStatus(newLoadingSpinnerStatus: string) {
    this.loadingSpinnerStatus.next(newLoadingSpinnerStatus);
  }
}
