import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { LoggingInterceptor } from './interceptor/http-interceptor';
import { routes } from './app.routes';
import { environment } from '../environments';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([LoggingInterceptor])),
    provideRouter(routes),
    { provide: 'acsIFrameID', useValue: 'acs-api' },
    {
      provide: 'acsIFrameUrl',
      useValue: `${environment.oAuth.acsBaseUrl}/acs2/login?client_id=${environment.oAuth.clientId}&scope=${environment.oAuth.scopes}&redirect_uri=${environment.oAuth.redirectUrl}`,
    },
  ],
};
