import {
  HttpErrorResponse,
  HttpEventType,
  HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import {
  catchError,
  finalize,
  throwError as observableThrowError,
  tap,
} from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { DataExchangeService } from '../services/data-exchange.service';

export const LoggingInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthenticationService);
  const dataExchangeService = inject(DataExchangeService);
  dataExchangeService.updateLoadingSpinnerStatus('true'); // Show spinner
  
  // console.log('Sending request to URL:', req.url);
  return next(req).pipe(
    tap((event) => {
      if (event.type === HttpEventType.Response) {
        // console.log(req.url, 'returned a response with status', event.status);
      }
    }),
    finalize(() => {
      dataExchangeService.updateLoadingSpinnerStatus('false'); // Hide spinner
    }),
    catchError((error: HttpErrorResponse) => {
      // console.error('Error from', req.url, ':', error.message);
      // console.log(authService.userToken);
      if (authService.userToken === '') {
        authService.logout();
      }
      return observableThrowError(() => new Error(error.message));
    })
  );
};
