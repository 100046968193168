<div class="opted-in-container">
  <div class="left">
    <h3 *ngIf="customer.customerName">{{customer.customerName}}</h3>
    <p *ngIf="optInData.optInDate" class="opted-in-date">Opted in on {{optInData.optInDate | date:'shortDate'}}</p>
  </div>
  <div class="right">
    <address>
      <span *ngIf="customer.addr1">{{customer.addr1}}<br/></span>
      <span *ngIf="customer.addr2">{{customer.addr2}}<br/></span>
      <span *ngIf="customer.addr3">{{customer.addr3}}<br/></span>
      <span *ngIf="customer.city">{{customer.city}}</span>, <span *ngIf="customer.state">{{customer.state}}</span>  <span *ngIf="customer.postalCode">{{customer.postalCode}}</span><br />
      <span *ngIf="customer.phone">{{customer.phone}}</span>
    </address>
    <div class="opt-out">
      <button class="opt-out-btn" (click)="optOut()">Opt Out</button>
    </div>
    <div class="modify-status">
      <button class="modify-status-btn" (click)="modifyEyefinityStatus()">Modify Eyefinity Status</button>
    </div>
  </div>
</div>

@if(cantFindBusinessAccounts){
  <div class="cant-find-business-accounts">
    <p class="error">Sorry, we can't find any business accounts with Eyefinity Account Number's.</p>
  </div>
}

<div class="business-accounts" *ngIf="businessAccounts.length > 0">
    <ul>
      <li class="business-accounts-header">
        <h3>EFAN Numbers</h3>
        <span>enable</span>
        <span>disable</span>
      </li>
      <li class="efan-number" *ngFor="let account of businessAccounts; index as i">
        <span>{{account.eyefinityAccountNumber}} - {{account.street}}</span> <!-- {{account.accountName}} -->
        <input type="radio" id="enable-{{account.eyefinityAccountNumber}}" (change)="updateEFANStatus(i, true)" [checked]="statusE[i]" name="{{account.eyefinityAccountNumber}}">
        <input type="radio" id="disable-{{account.eyefinityAccountNumber}}" (change)="updateEFANStatus(i, false)" [checked]="statusD[i]" name="{{account.eyefinityAccountNumber}}">
      </li>
    </ul>
    <div class="btn-group">
      <button class="updateBA-btn" (click)="saveEFANStatus()">Update</button> 
      <button class="resetBA-btn" (click)="resetStatus()">Reset</button> 
    </div>
</div> 