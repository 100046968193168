import { IEnvironment } from './environment.interface';
export const environment: IEnvironment = {
  api: 'https://api-integration.mymarchon.com',
  cmsHost: 'https://cdn-acpt.vsp.com/',
  oAuth: {
    acsBaseUrl: 'https://api-integration.mymarchon.com',
    clientId: 'efkscopestatusspa-dev',
    scopes:
      'auth_employee_sso write:mar.customermarketingprograms read:mar.customermarketingprograms read:mar.organizationservices read:mar.customerservices read:mar.esbcustomergetcustomerlistweb read:mar.proc-kaleyedoscope-api write:mar.proc-kaleyedoscope-api',
    redirectUrl: 'https://efkscopestatus-dev.pub.marchon.com',
  },
  muleHost: 'https://api-dev.pub.vsp.com'
};
