<div class="opted-in-container">
  <div class="left">
    <h3 *ngIf="customer.customerName">{{customer.customerName}}</h3>
  </div>
  <div class="right">
    <address>
      <span *ngIf="customer.addr1">{{customer.addr1}}<br/></span>
      <span *ngIf="customer.addr2">{{customer.addr2}}<br/></span>
      <span *ngIf="customer.addr3">{{customer.addr3}}<br/></span>
      <span *ngIf="customer.city">{{customer.city}}</span>, <span *ngIf="customer.state">{{customer.state}}</span>  <span *ngIf="customer.postalCode">{{customer.postalCode}}</span><br />
      <span *ngIf="customer.phone">{{customer.phone}}</span>
    </address>
    <div class="opt-in">
      <button class="opt-in-btn" (click)="optIn()">Opt In</button>
    </div>
  </div>
</div>