import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments';
import { UserProfile } from './user-profile';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public authenticationHeader!: HttpHeaders;
  public userToken!: string;
  public userProfile!: UserProfile | null;
  private jwtHelperService: JwtHelperService;
  private tokenSubject = new BehaviorSubject<string>('');
  private userSubject = new BehaviorSubject<UserProfile | null>(null);
  private acsIFrameUrl: any;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject('acsIFrameID') private acsIFrameID: string,
    @Inject('acsIFrameUrl') private acsIFrameURL: string
  ) {
    this.jwtHelperService = new JwtHelperService();
    this.acsIFrameUrl =
      this.sanitizer.sanitize(
        SecurityContext.URL,
        [
          environment.oAuth.acsBaseUrl,
          '/acs2/login?client_id=',
          environment.oAuth.clientId,
          '&scope=',
          environment.oAuth.scopes,
          '&redirect_uri=',
          environment.oAuth.redirectUrl,
        ].join('')
      ) ?? '';

    this.tokenSubject.subscribe((userToken) => {
      if ((this.userToken = userToken) === '') this.userSubject.next(null);
    });
  }

  private static readonly LOGIN_COMMAND = { type: 'login' };
  private static readonly LOGOUT_COMMAND = { type: 'logout' };

  private static executeAction(commandMessage: { type: string }): void {
    const acsApiElement: HTMLIFrameElement = document.getElementById(
      'acs-api'
    ) as HTMLIFrameElement;
    const acsApiIframe: any = acsApiElement.contentWindow;
    acsApiIframe.postMessage(
      JSON.stringify(commandMessage),
      document.location.origin
    );
  }

  public setUser = (user: UserProfile | null) => {
    this.userProfile = user;
    this.userSubject.next(user);
  };

  public login(): void {
    sessionStorage.setItem('loggedInAttempted', 'true');
    AuthenticationService.executeAction(AuthenticationService.LOGIN_COMMAND);
    document.location.href = encodeURI(this.acsIFrameUrl);
  }

  public logout(): void {
    this.tokenSubject.next('');
    this.userSubject.next(null);
    sessionStorage.removeItem('loggedIn');
    sessionStorage.removeItem('loggedInAttempted');
    AuthenticationService.executeAction(AuthenticationService.LOGOUT_COMMAND);
    setTimeout(() => {
      document.location.href = encodeURI(
        this.sanitizer.sanitize(
          SecurityContext.URL,
          environment.oAuth.redirectUrl
        ) ?? ''
      );
    }, 500);
  }

  public receiveToken(token: string): void {
    this.userToken = token;
    this.tokenSubject.next(token);
    sessionStorage.setItem('loggedIn', 'true');
    this.setUser(this.jwtHelperService.decodeToken(this.userToken));
    // console.log(this.userProfile);
    this.authenticationHeader = new HttpHeaders({
      Authorization: this.userToken,
    });
  }
}
