import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuardService {
  constructor(private authService: AuthenticationService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (!!sessionStorage.getItem('loggedIn')) {
      return of(true);
    } else {
      this.authService.login();
      return of(false);
    }
  }
}
