import { Component, Input } from '@angular/core';
import { IFindCustomerMktProgram } from '../interfaces/find-customer-mkt-program';
import { ApiService } from '../services/api.service';
import { take } from 'rxjs';
import { DataExchangeService } from '../services/data-exchange.service';
import { ICustomer } from '../interfaces/customer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-not-in-kscope',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './not-in-kscope.component.html',
  styleUrl: './not-in-kscope.component.scss',
})
export class NotInKscopeComponent {
  @Input() optInData: IFindCustomerMktProgram = {} as IFindCustomerMktProgram;
  @Input() customer: ICustomer = {} as ICustomer;
  @Input() userCredential: any = {};

  constructor(
    public apiService: ApiService,
    private dataExchangeService: DataExchangeService
  ) {}

  ngOnInit(): void {}

  optIn() {
    const today = new Date().toISOString().split('T')[0];
    const params: IFindCustomerMktProgram = {
      soldTo: `${this.optInData.soldTo}`,
      salesOrg: `${this.optInData.salesOrg}`,
      mktProgramName: this.optInData.mktProgramName || 'ARP',
      eligibility: this.optInData.eligibility || 'Y',
      optInStatus: `Y`,
      autoShip: this.optInData.autoShip || 'N',
      optInDate: today,
      userCredential: this.userCredential,
    } as any as IFindCustomerMktProgram;

    this.apiService
      .updateStatus(params)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.serviceStatus.resultCode === 0) {
          this.dataExchangeService.updateOptInStatus('Updated');
        } else {
          this.dataExchangeService.updateOptInStatus('Error');
        }
      });
  }
}
