import { Component, Inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments';
import { AuthenticationService } from './authentication/authentication.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(
    public authService: AuthenticationService,
    private router: Router,
    @Inject('acsIFrameID') private acsIFrameID: string,
    @Inject('acsIFrameUrl') private acsIFrameURL: string
  ) {}

  ngOnInit(): void {
    this.setupIframe();
  }

  private setupIframe() {
    const url = [
      environment.oAuth.acsBaseUrl,
      '/acs2/iframe?client_id=',
      environment.oAuth.clientId,
      '&scope=',
      environment.oAuth.scopes,
      '&redirect_uri=',
      environment.oAuth.redirectUrl,
    ].join('');

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.id = 'acs-api';
    document.body.appendChild(iframe);

    if (!!sessionStorage.getItem('loggedInAttempted')) {
      window.addEventListener('message', (e: MessageEvent) => {
        if (
          e.origin === environment.oAuth.acsBaseUrl &&
          String(e.data).indexOf('Bearer') >= 0
        ) {
          this.authService.receiveToken(e.data);
          this.router.navigate(['/home']);
        }
      });

      setTimeout(() => {
        // console.log(this.authService.userToken);
        if (this.authService.userToken === '') {
          this.authService.login()
        }
      }, 5000);

    } else {
      this.authService.login();
    }
  }
}
