import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthenticationGuardService } from './authentication/authentication-guard.service';
import { AppComponent } from './app.component';

export const routes: Routes = [
  {
    path: '',
    title: 'Eyefinity KScope Status',
    component: AppComponent,
  },
  {
    path: 'home',
    title: 'Eyefinity KScope Status',
    component: HomeComponent,
    canActivate: [AuthenticationGuardService],
  },
  { path: '**', redirectTo: '' },
];
